<template>
  <div class="room--label-container" v-if="listing">
    <div class="label--room" v-if="listing.bedroom != null">
      <v-icon>mdi-bed</v-icon>
      <div class="label--answer" v-if="listing.bedroom > 0">{{ listing.bedroom }}</div>
      <div class="label--answer" v-else>{{ $t('general.studio') }}</div>
    </div>
    <div class="label--room" v-if="listing.bathroom">
      <v-icon>mdi-shower-head</v-icon>
      <div class="label--answer">{{ listing.bathroom }}</div>
    </div>
    <div class="label--room" v-if="listing.land_size">
      <div>
        <span>LT: </span>
        <span class="label--answer">{{ `${listing.land_size} m²` }}</span>
      </div>
    </div>
    <div class="label--room" v-if="listing.building_size">
      <div>
        <span>LB: </span>
        <span class="label--answer">{{ `${listing.building_size} m²` }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {},
  props: {
    listing: {
      default: null,
      require: true,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({}),
  },
  methods: {},
};
</script>
